@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@500;600&family=Genos:ital@0;1&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  /* height: calc(var(--vh, 1vh)*100); */
  box-sizing: border-box;
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  /* overflow-y: scroll; */
  /* scroll-behavior: smooth; */
  /* overflow-y: hidden; */
}
::-webkit-scrollbar {
  width: 0;
}
/* ::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: transparent;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: purple;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #fff3;
} */
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Genos', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #002642;
  overscroll-behavior-y: none;
  max-height: calc(var(--vh, 1vh) * 100);
  /* -webkit-overflow-scrolling: touch; */
  /* overflow-y: scroll; */
}
main {
  display: block;
}
h1 {
  font-size: 2em;
}
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
input {
  padding: 0;
  background-color: transparent;
  border: none;
}
input:focus-visible {
  outline: none;
}
button,
input { /* 1 */
  overflow: visible;
}
button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
}
button,
select { /* 1 */
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
::selection {
  background: gray;
  color: white;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}

:root {
  --titleFirst: 600 394px/390px 'Archivo';
  --titleSecond: 600 300px/300px 'Archivo';
  --titleThird: 600 200px/200px 'Archivo';
  --titleFourth: 500 44px/56px 'Archivo';
  --titleFifth: 500 30px/40px 'Archivo';
  --textFirst: 400 160px/130px 'Genos';
  --textSecond: 400 117px/100px 'Genos';
  --textThird: 400 80px/80px 'Genos';
  --textFourth: 400 70px/64px 'Genos';
}
@media (max-width: 3839px){
  :root {
    --titleFirst: 600 262px/270px 'Archivo';
    --titleSecond: 600 200px/210px 'Archivo';
    --titleThird: 600 140px/150px 'Archivo';
    --titleFourth: 500 32px/42px 'Archivo';
    --titleFifth: 500 24px/32px 'Archivo';
    --textFirst: 400 120px/100px 'Genos';
    --textSecond: 400 80px/73px 'Genos';
    --textThird: 400 60px/56px 'Genos';
    --textFourth: 400 50px/50px 'Genos';
  }
}
@media (max-width: 2559px){
  :root {
    --titleFirst: 600 198px/210px 'Archivo';
    --titleSecond: 600 140px/134px 'Archivo';
    --titleThird: 600 100px/100px 'Archivo';
    --titleFourth: 500 20px/28px 'Archivo';
    --titleFifth: 500 16px/20px 'Archivo';
    --textFirst: 400 90px/74px 'Genos';
    --textSecond: 400 60px/58px 'Genos';
    --textThird: 400 40px/42px 'Genos';
    --textFourth: 400 40px/42px 'Genos';
  }
}
@media (max-width: 1919px){
  :root {
    --titleFirst: 600 138px/135px 'Archivo';
    --titleSecond: 600 100px/100px 'Archivo';
    --titleThird: 600 70px/70px 'Archivo';
    --titleFourth: 500 18px/22px 'Archivo';
    --titleFifth: 500 14px/18px 'Archivo';
    --textFirst: 400 60px/48px 'Genos';
    --textSecond: 400 40px/38px 'Genos';
    --textThird: 400 30px/32px 'Genos';
    --textFourth: 400 24px/29px 'Genos';
  }
}
@media (max-width: 1365px){
  :root {
    --titleFirst: 600 100px/95px 'Archivo';
    --titleSecond: 600 80px/80px 'Archivo';
    --titleThird: 600 60px/65px 'Archivo';
    --titleFourth: 500 18px/22px 'Archivo';
    --titleFifth: 500 14px/18px 'Archivo';
  }
}
@media (max-width: 767px) {
  :root {
    --titleFirst: 600 64px/61px 'Archivo';
    --titleSecond: 600 48px/50px 'Archivo';
    --titleThird: 600 16px/22px 'Archivo';
    --titleFourth: 500 16px/22px 'Archivo';
    --titleFifth: 500 12px/16px 'Archivo';
    --textFirst: 400 40px/33px 'Genos';
    --textSecond: 400 28px/26px 'Genos';
    --textThird: 400 20px/24px 'Genos';
  }
}


ul {
 margin: 0;
 padding: 0;
 list-style-type: none;
}

html.has-scroll-smooth {
  overflow: hidden; }

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.has-scroll-smooth body {
  overflow: hidden; }

.has-scroll-smooth [data-scroll-container] {
  min-height: calc(var(--vh, 1vh) * 100); }

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: calc(var(--vh, 1vh) * 100);
  display: inline-block;
  white-space: nowrap; }

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%; }

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; }
  .c-scrollbar:hover {
    transform: scaleX(1.45); }
  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; }
  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); }
    [data-scroll-direction="horizontal"] .c-scrollbar:hover {
      transform: scaleY(1.3); }

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

.container {
  margin: 0 auto;
  max-width: 3840px;
}
@media (max-width: 3839px){
  .container {
    max-width: 2560px;
  }
}
@media (max-width: 2559px){
  .container {
    max-width: 1920px;
  }
}
@media (max-width: 1919px){
  .container {
    max-width: 1366px;
  }
}
@media (max-width: 1365px){
  .container {
    max-width: 768px;
  }
}
@media (max-width: 767px){
  .container {
    max-width: 375px;
  }
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #002642;
  z-index: 2500;
}

.background-color {
  position: relative;
  background-color: #002642;
  z-index: 100;
}

@media (min-width: 1024px) {
  .anim-text {
    display: block;
    overflow: hidden;
  }

  .anim-text:hover span {
    transform: translateY(-125%);
  }

  .anim-text:hover span::after {
    transform: translateY(125%);
  }

  .anim-text span {
    display: inline-block;
    position: relative;
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .anim-text span::after {
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(150%);
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
